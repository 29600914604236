import React, { ChangeEvent, FormEvent, useState, useEffect, useContext } from "react";
import { logEvent } from "@src/utils/logClient";
import { Button, Heading, theme } from "@src/components/nessie-web";
import styled from "@emotion/styled";
import Container from "@src/components/Container";
import { mediaQueriesMax } from "@src/styles/theme";
import SEO from "@src/components/SEO";
import Translate from "@src/components/translation/Translate";
import MarkedTranslate from "@src/components/translation/MarkedTranslate";
import { generatingFor } from "@src/utils/routes";
import { graphql, useStaticQuery } from "gatsby";
import { TranslationContext } from "@src/components/translation/TranslationContext";

interface PageContainerProps {
  backgroundImageUrl: string;
}

interface PageBackgroundProps {
  photo1Url: string;
  photo2Url: string;
}

const PageContainer = styled(Container)<PageContainerProps>`
  ${mediaQueriesMax[0]} {
    padding-bottom: 200px;
  }
  background: white;
  border-radius: 30px;
  padding: 30px;
  max-width: 700px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 18px;
  background-image: url(${(props) => props.backgroundImageUrl});
  background-repeat: no-repeat;
  background-position: bottom right;
  input[type="email"] {
    display: block;
    margin-top: 6px;
    width: 100%;
    padding: 12px 24px;
    border-radius: 99px;
    border: 2px solid ${theme.colors.taro30};
    background-color: ${theme.colors.taro10};
  }
`;
const PageBackground = styled.div<PageBackgroundProps>`
  background-color: #f5ebff;
  padding-block: 60px;
  background-image: url(${(props) => props.photo1Url}), url(${(props) => props.photo2Url});
  background-position: left 20%, right 80%;
  background-repeat: no-repeat;
  ${mediaQueriesMax[0]} {
    background-image: none;
  }
`;

const MoreForMentors = () => {
  const data = useStaticQuery(graphql`
    {
      logo: file(relativePath: { eq: "moreformentors/ms-abbott.png" }) {
        publicURL
      }
      mojo: file(relativePath: { eq: "cultofpedagogy/mojo.svg" }) {
        publicURL
      }
      photo1: file(relativePath: { eq: "cultofpedagogy/photo1.png" }) {
        publicURL
      }
      photo2: file(relativePath: { eq: "cultofpedagogy/photo2.png" }) {
        publicURL
      }
    }
  `);
  const { logo, mojo, photo1, photo2 } = data;
  const t = useContext(TranslationContext);

  const [email, setEmail] = useState("");

  const lang = generatingFor.locale;

  function handleSubmit(ev: FormEvent) {
    ev.preventDefault();
    logEvent({
      eventName: "web.external.real_ms_abbott.button_click",
      eventValue: email,
    });

    const pdfUrl = "https://static.classdojo.com/img/2024/10/Abbott_Kit.zip";
    // if (lang.startsWith("es")) {
    //   pdfUrl = "https://static.classdojo.com/img/2024/01/MentorMissionKit_es-mx.pdf";
    // } else if (lang.startsWith("fr")) {
    //   pdfUrl = "https://static.classdojo.com/img/2024/01/MentorMissionKit_fr.pdf";
    // } else if (lang.startsWith("pt")) {
    //   pdfUrl = "https://static.classdojo.com/img/2024/01/MentorMissionKit_pt-br.pdf";
    // }

    window.location.href = pdfUrl;
  }

  function handleEmailChange(event: ChangeEvent<HTMLInputElement>) {
    setEmail(event.target.value);
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const emailParam = urlParams.get("email");
    if (emailParam) setEmail(emailParam);

    logEvent({
      eventName: "web.external.more_for_mentors.page_view",
      eventValue: window.location.href,
    });
  }, []);

  return (
    <PageBackground photo1Url={photo1.publicURL} photo2Url={photo2.publicURL}>
      <SEO
        title="More for Mentors with Real Ms. Abbott"
        description="directus.page_more_for_mentors.q3_description"
        image={logo.publicURL}
        twitter={{
          card: "summary_large_image",
          site: "@classdojo",
          creator: "@classdojo",
          title: "More for Mentors with Real Ms. Abbott",
          description: "directus.page_more_for_mentors.q3_description",
          image: logo.publicURL,
        }}
      />
      <Container>
        <PageContainer backgroundImageUrl={mojo.publicURL}>
          <img src={logo.publicURL} alt="The Future of Learn is Play" />
          <Heading>
            <Translate path="directus.page_more_for_mentors.q3_description" />
          </Heading>
          <div css={{ width: "100%" }}>
            <MarkedTranslate path="directus.page_more_for_mentors.q3_text" />
          </div>
          <form
            onSubmit={handleSubmit}
            css={{ display: "flex", flexDirection: "column", gap: 16, width: "100%", maxWidth: 300 }}
          >
            <input
              type="email"
              id="email"
              value={email}
              onChange={handleEmailChange}
              required
              placeholder="Enter your e-mail"
            />
            <Button kind="plus" type="submit">
              <Translate path="directus.page_more_for_mentors.q3_button_cta" />
            </Button>
          </form>
        </PageContainer>
      </Container>
    </PageBackground>
  );
};

export default MoreForMentors;
